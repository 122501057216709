import React, {useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"

import formatBOYSEN from "../../lib/formatBoysen"
import formatTools from "../../lib/formatTools"

import PaintHeader from "../components/paintcalculator/header"
import ProductInfo from "../components/paintingtools/productinfo"
import ProductDetails from "../components/paintingtools/productdetails"

import PaintTable from "../components/paintcalculator/painttable"

import * as styles from "../styles/pages/productcalculator.module.css"

const ProductcalculatorTemplate = ({location, pageContext, data}) => {
	const [printmode, setPrintmode] = useState(false);
	const [printsetting, setPrintsetting] = useState({
		sqm:30,
		optionidx:-1,
		optionstart:-1,
		optionend:-1,
	});

	const pagecalculator = data.calculator.edges[0].node.childrenJson[0].calculator;
	const pageinstruction = data.calculator.edges[0].node.childrenJson[0].instructions;
	const pagedetail = data.detail.edges[0].node.childrenJson[0].info;
	const pagelink = data.detail.edges[0].node.childrenJson[0].linkdata;
	const pagecolor = data.color.edges[0].node.childrenJson;
	const pagetech = data.tech.edges[0].node.childrenJson[0];

	function handlePageAction(action)
	{
		if (action === "print") {
			setPrintmode(true);
			setTimeout(function() {
				window.print();
				setPrintmode(false);
			}, 200);
		}
	}

	function handlePaintTableChange(newsetting)
	{
		setPrintsetting(newsetting);
	}

	if (printmode) {
		return <div>
			<div>
				<PaintTable surfacecalc={pagecalculator} paintid={""} surfacetext={""} settingValue={printsetting} printmode={true} />
			</div>
			<div>
				<br/><hr/><br/>
			</div>
			<div className="text-centered font-size-small">
				<div>
					{formatTools.getDateStr()}
				</div>
			</div>
			<div>
				<br/><br/>
			</div>
		</div>
	}

	return  (
		<Layout activePath={"products/"+pageContext.item.url+"/"+pageContext.paintmode+"/"+pageContext.surfacecode} pageImage={pageContext.item.imageurl} pageTitle={formatBOYSEN.stripNameTags(pageContext.item.name+" "+pageContext.item.subtitle)} pageDescription={formatBOYSEN.stripNameTags(pageContext.item.name+" "+pageContext.item.subtitle)}>
			<PaintHeader mode={pageContext.paintmode} productnamemarkup={pageContext.item.name+" "+pageContext.item.subtitle} surfacecode={pageContext.surfacecode} />
			<ProductInfo className={styles.calculatorpage} productdata={pageContext.item} productdetail={pagedetail} productlink={pagelink} />
			<ProductDetails producturl={"products/"+pageContext.item.url} pageActionHandler={handlePageAction} className={styles.calculatorpage} productdetail={pagedetail} productcolor={pagecolor} producttech={pagetech} productinstruction={pageinstruction} productcalculator={pagecalculator} productmode={pageContext.paintmode} pageTableHandler={handlePaintTableChange} />
		</Layout>
	)
};

export default ProductcalculatorTemplate

// Accessed as "data"
export const querydata = graphql`
	query ($detail: String!, $color: String!, $tech: String!, $calculator: String!) {
		detail: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $detail} }
		) {
			edges {
				node {
					childrenJson {
						info {
							body
							subtitle
							title
							variant
						}
						linkdata {
							url
							title
						}
					}
				}
			}
		}
		color: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $color} }
		) {
			edges {
				node {
					childrenJson {
						jsonId
						code
						name
						variant
						imageurl
						red
						green
						blue
					}
				}
			}
		}
		tech: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $tech} }
		) {
			edges {
				node {
					childrenJson {
						variants {
							id
							name
						}
						details {
							name
							info
							idx
						}
					}
				}
			}
		}
		calculator: allFile(
			filter: {sourceInstanceName: {eq: "calculator"}, name: {eq: $calculator} }
		) {
			edges {
				node {
					childrenJson {
						instructions {
							title
							data
						}
						calculator {
							title
							header
							factor
							item
							unit
							note
							decimal
							mathfloorfactor
						}
					}
				}
			}
		}
	}
`
