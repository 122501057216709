// extracted by mini-css-extract-plugin
export var holder = "header-module--holder--08839";
export var holderhidden = "header-module--holderhidden--a0a93";
export var holderfrozen = "header-module--holderfrozen--3094f";
export var holdertextproductname = "header-module--holdertextproductname--28686";
export var holdertext = "header-module--holdertext--7cbdd";
export var holdertextborder = "header-module--holdertextborder--e3ee3";
export var holdertextborderleft = "header-module--holdertextborderleft--17c7d";
export var holderarrowinfo = "header-module--holderarrowinfo--a695e";
export var holdertextinfo = "header-module--holdertextinfo--16dad";
export var holderbodystart = "header-module--holderbodystart--ebb97";
export var holderbodymiddle = "header-module--holderbodymiddle--480fa";
export var holdertextborderfilled = "header-module--holdertextborderfilled--b748f";
export var holderbodyfilled = "header-module--holderbodyfilled--36f0a";
export var holderarrowmiddle = "header-module--holderarrowmiddle--07744";
export var holderarrowprefilled = "header-module--holderarrowprefilled--cb6d4";
export var holderarrowfilled = "header-module--holderarrowfilled--317c6";